var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dashboard"},[_c('v-container',[_c('h2',{staticClass:"text-left align-center pt-1 pb-10"},[_vm._v(" Welcome, "),_c('span',{staticClass:"pink--text lighten-3"},[_vm._v(_vm._s(_vm.username))])]),_c('v-simple-table',{staticClass:"dashboard__data-table",attrs:{"dark":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-center font-weight-medium text-uppercase",attrs:{"width":"30%"}},[_vm._v(" Creative Name"),_c('br'),_vm._v("Type ")]),_c('th',{staticClass:"text-center font-weight-medium d-none d-md-table-cell d-lg-table-cell d-xl-table-cell text-uppercase",attrs:{"width":"10%"}},[_vm._v(" Flight Start ")]),_c('th',{staticClass:"text-center font-weight-medium d-none d-md-table-cell d-lg-table-cell d-xl-table-cell text-uppercase",attrs:{"width":"10%"}},[_vm._v(" Flight End ")]),_c('th',{staticClass:"text-center font-weight-medium d-none d-md-table-cell d-lg-table-cell d-xl-table-cell text-uppercase",attrs:{"width":"20%"}},[_vm._v(" Last Updated"),_c('br'),_vm._v("Author ")]),_c('th',{staticClass:"text-center font-weight-medium text-uppercase",attrs:{"width":"10%"}},[_vm._v(" Actions ")])])]),_c('tbody',{staticClass:"transparent"},_vm._l((_vm.isSearching
                            ? _vm.searchedData
                            : _vm.data),function(creative,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[(creative.userAbilities.update)?[_c('span',{staticClass:"dashboard__data-table__creative-title--is-anchor",on:{"click":function($event){return _vm.goTo(creative.type, creative.id)}}},[_vm._v(_vm._s(creative.title))]),_c('br')]:[_vm._v(" "+_vm._s(creative.title)),_c('br')],_c('strong',{class:'dashboard__data-table__creative-type text-uppercase ' +
                                        _vm.classes[creative.type],on:{"click":function($event){creative.userAbilities.update
                                        ? _vm.goTo(creative.type, creative.id)
                                        : () => {
                                              return;
                                          }}}},[_vm._v(_vm._s(creative.type))])],2),_c('th',{staticClass:"text-center d-none d-md-table-cell d-lg-table-cell d-xl-table-cell"},[_vm._v(" "+_vm._s(creative.start_date)+" ")]),_c('th',{staticClass:"text-center d-none d-md-table-cell d-lg-table-cell d-xl-table-cell"},[_vm._v(" "+_vm._s(creative.end_date)+" ")]),_c('th',{staticClass:"text-center d-none d-md-table-cell d-lg-table-cell d-xl-table-cell"},[_vm._v(" "+_vm._s(_vm.parseDate(creative.updated_at))),_c('br'),_vm._v(" "+_vm._s(creative.user)+" ")]),_c('th',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
                                            creative.type === 'standalone'
                                        )?_c('v-btn',_vm._g({attrs:{"to":{
                                            name: creative.type,
                                            params: {
                                                id: creative.id,
                                                selectedTab: 'analytics'
                                            }
                                        },"icon":"","small":""}},on),[_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("fa-chart-line ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Analytics")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
                                            creative.type ===
                                                'socialmosaic' ||
                                                creative.type ===
                                                    'sweepstakes'
                                        )?_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.creativeDownload(
                                                creative.type,
                                                creative.id,
                                                creative.hash
                                            )}}},on),[_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("fa-download ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Download")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(creative.userAbilities.update)?_c('v-btn',_vm._g({attrs:{"to":{
                                            name: creative.type,
                                            params: {
                                                id: creative.id,
                                                selectedTab: 'edit'
                                            }
                                        },"icon":"","small":""}},on),[_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("fa-cogs ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(creative.userAbilities.delete)?_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteCreative(
                                                creative.creative_id
                                            )}}},on),[_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("fa-trash-alt ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)])}),0)]},proxy:true}])}),(_vm.loading === 'loading')?_c('div',[_c('v-progress-circular',{staticClass:"bottom",attrs:{"color":"blue-grey darken-1","indeterminate":""}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }