<template>
    <div class="dashboard">
        <v-container>
            <h2 class="text-left align-center pt-1 pb-10">
                Welcome,
                <span class="pink--text lighten-3">{{ username }}</span>
            </h2>

            <v-simple-table class="dashboard__data-table" dark>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th
                                class="text-center font-weight-medium text-uppercase"
                                width="30%"
                            >
                                Creative Name<br />Type
                            </th>
                            <th
                                class="text-center font-weight-medium d-none d-md-table-cell d-lg-table-cell d-xl-table-cell text-uppercase"
                                width="10%"
                            >
                                Flight Start
                            </th>
                            <th
                                class="text-center font-weight-medium d-none d-md-table-cell d-lg-table-cell d-xl-table-cell text-uppercase"
                                width="10%"
                            >
                                Flight End
                            </th>
                            <th
                                class="text-center font-weight-medium d-none d-md-table-cell d-lg-table-cell d-xl-table-cell text-uppercase"
                                width="20%"
                            >
                                Last Updated<br />Author
                            </th>
                            <th
                                class="text-center font-weight-medium text-uppercase"
                                width="10%"
                            >
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody class="transparent">
                        <tr
                            v-for="(creative, index) in isSearching
                                ? searchedData
                                : data"
                            :key="index"
                        >
                            <td class="text-center">
                                <template v-if="creative.userAbilities.update">
                                    <span
                                        class="dashboard__data-table__creative-title--is-anchor"
                                        @click="
                                            goTo(creative.type, creative.id)
                                        "
                                        >{{ creative.title }}</span
                                    ><br />
                                </template>
                                <template v-else>
                                    {{ creative.title }}<br />
                                </template>
                                <strong
                                    :class="
                                        'dashboard__data-table__creative-type text-uppercase ' +
                                            classes[creative.type]
                                    "
                                    @click="
                                        creative.userAbilities.update
                                            ? goTo(creative.type, creative.id)
                                            : () => {
                                                  return;
                                              }
                                    "
                                    >{{ creative.type }}</strong
                                >
                            </td>
                            <th
                                class="text-center d-none d-md-table-cell d-lg-table-cell d-xl-table-cell"
                            >
                                {{ creative.start_date }}
                            </th>
                            <th
                                class="text-center d-none d-md-table-cell d-lg-table-cell d-xl-table-cell"
                            >
                                {{ creative.end_date }}
                            </th>
                            <th
                                class="text-center d-none d-md-table-cell d-lg-table-cell d-xl-table-cell"
                            >
                                {{ parseDate(creative.updated_at) }}<br />
                                {{ creative.user }}
                            </th>
                            <th class="text-center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-if="
                                                creative.type === 'standalone'
                                            "
                                            :to="{
                                                name: creative.type,
                                                params: {
                                                    id: creative.id,
                                                    selectedTab: 'analytics'
                                                }
                                            }"
                                            icon
                                            small
                                            v-on="on"
                                        >
                                            <v-icon color="accent" small
                                                >fa-chart-line
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Analytics</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-if="
                                                creative.type ===
                                                    'socialmosaic' ||
                                                    creative.type ===
                                                        'sweepstakes'
                                            "
                                            icon
                                            small
                                            @click="
                                                creativeDownload(
                                                    creative.type,
                                                    creative.id,
                                                    creative.hash
                                                )
                                            "
                                            v-on="on"
                                        >
                                            <v-icon color="accent" small
                                                >fa-download
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Download</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-if="creative.userAbilities.update"
                                            :to="{
                                                name: creative.type,
                                                params: {
                                                    id: creative.id,
                                                    selectedTab: 'edit'
                                                }
                                            }"
                                            icon
                                            small
                                            v-on="on"
                                        >
                                            <v-icon color="accent" small
                                                >fa-cogs
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-if="creative.userAbilities.delete"
                                            icon
                                            small
                                            @click="
                                                deleteCreative(
                                                    creative.creative_id
                                                )
                                            "
                                            v-on="on"
                                        >
                                            <v-icon color="accent" small
                                                >fa-trash-alt
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </th>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>

            <div v-if="loading === 'loading'">
                <v-progress-circular
                    class="bottom"
                    color="blue-grey darken-1"
                    indeterminate
                />
            </div>
        </v-container>
    </div>
</template>

<script>
import Rest from '@/services/rest.service';
import Constants from '@/constants';
import _ from 'lodash';

export default {
    name: 'Dashboard',

    props: ['keywords'],

    computed: {
        username() {
            const username = this.user.username.replace('.', ' ');
            return _.capitalize(username);
        },

        user() {
            return this.$store.getters['auth/user'];
        },

        data() {
            return this.$store.getters['creatives/data'];
        },

        loading() {
            return this.$store.getters['creatives/status'];
        },

        searchedData() {
            return this.$store.getters['creatives/searchedData'];
        }
    },

    data: () => ({
        available: true,
        bottom: false,
        isSearching: false,
        classes: {
            sweepstakes: 'light-blue--text darken-1',
            socialmosaic: 'cyan--text darken-1',
            locatorconverter: 'teal--text darken-1',
            feedcreator: 'green--text darken-1',
            poll: 'orange--text darken-1',
            massemail: 'light-green--text darken-1',
            tracker: 'yellow--text darken-1',
            standalone: 'blue-grey--text darken-1'
        }
    }),

    mounted() {
        this.$store
            .dispatch('creatives/getTypes', this.user.role)
            .then(() => {})
            .catch(() => {});
        this.fetch();
    },

    created() {
        if (this.available) {
            window.addEventListener('wheel', () => {
                this.bottom = this.bottomVisible();
            });
        }
    },

    watch: {
        bottom(bottom) {
            if (bottom && this.available) {
                this.fetch();
            }
        },

        keywords(val) {
            if (val !== '') {
                this.search(val);
            } else {
                this.isSearching = false;
            }
        }
    },

    methods: {
        fetch() {
            this.$store
                .dispatch('creatives/get')
                .then(data => {
                    this.available = data;
                })
                .catch(() => {});
        },

        bottomVisible() {
            const { scrollTop, offsetHeight } = document.documentElement;
            const { innerHeight } = window;
            return Math.round(scrollTop) + innerHeight === offsetHeight;
        },

        search(kw) {
            this.$store
                .dispatch('creatives/search', kw)
                .then(() => {
                    this.isSearching = true;
                    this.available = false;
                })
                .catch(() => {});
        },

        /**
         * Redirects to creative edit.
         * @param type
         * @param id
         */
        goTo(type, id) {
            this.$router.push({
                name: type,
                params: {
                    id: id,
                    selectedTab: 'edit'
                }
            });
        },

        /**
         * Deletes the selected creative.
         * @param creativeID
         */
        deleteCreative(creativeID) {
            const itemFound = this.data.find(
                item => item.creative_id === creativeID
            );
            const itemIndex = this.data.indexOf(itemFound);

            this.$store
                .dispatch('creatives/delete', creativeID)
                .then(() => {
                    this.data.splice(itemIndex, 1);
                })
                .catch(() => {});
        },

        /**
         * Format given date.
         * @param date
         */
        parseDate(date) {
            return new Date(date).toLocaleString();
        },

        /**
         * Generates an excel or pdf depending on creative type.
         * @param type
         * @param creativeID
         */
        creativeDownload(type, creativeID) {
            if (type === 'socialmosaic') {
                const token = this.$store.getters['auth/token'];
                const url =
                    Constants.APP_URL +
                    '/api/v1/i/socialmosaics/' +
                    creativeID +
                    '/socialreach?token=' +
                    token;
                window.open(url);
            } else {
                Rest.post(
                    'sweepstakes/' + creativeID + '/entries/excel'
                ).catch(() => {});
            }
        }
    }
};
</script>

<style lang="scss">
.dashboard {
    &__data-table__creative-title--is-anchor,
    &__data-table__creative-type {
        cursor: pointer;
    }
}
</style>
